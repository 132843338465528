import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import { getTrialDaysLeft } from '@pages/advertiser/BillingPage/SubscriptionTab/SubscriptionTab';
import * as Sentry from '@sentry/react';

import { Alert } from '@mui/material';

import { useOldAppState } from '@hooks/useOldAppState';

import { ParamTypes } from '@libs/getSharedVar';

export default function SubscriptionWarningBanner() {
    const [isOpen, setIsOpen] = useState(true);
    const oldGlobalState = useOldAppState().state;
    const advertiser = oldGlobalState.advertiser;

    if (!isOpen || !advertiser) {
        return null;
    }

    const freeTrialEndSoon =
        advertiser.status === 'freetrial' &&
        getTrialDaysLeft(advertiser) <= 5 &&
        advertiser.plan === 'free';
    if (
        (!freeTrialEndSoon && advertiser.status !== 'unpaid') ||
        advertiser.paymentMethod === 'bankwire'
    ) {
        return null;
    }

    const SubscriptionLink = (props: { children: React.ReactNode }) => {
        const params = useParams<ParamTypes>();
        const subscriptionLink = generatePath('/:locale/advertiser/billing/subscription', params);
        return <Link to={subscriptionLink} onClick={() => setIsOpen(false)} {...props} />;
    };

    let warningMessage: React.ReactNode = null;
    if (freeTrialEndSoon) {
        warningMessage = (
            <Trans i18nKey='subscriptionWarning_YourFreeTrialWillExpire'>
                Your free trial will expire soon. <SubscriptionLink>Subscribe now</SubscriptionLink>
            </Trans>
        );
    } else if (advertiser.status === 'unpaid') {
        warningMessage = (
            <Trans i18nKey='subscriptionWarning_YourLastPaymentFailed'>
                {'Your credit card has been refused for your last payment. '}
                <SubscriptionLink>Subscribe now</SubscriptionLink>
            </Trans>
        );
    } else {
        Sentry.captureException(new Error('Unexpected advertiser status for subscription warning'));
    }

    return (
        <>
            {isOpen && (
                <Alert
                    severity='warning'
                    icon={false}
                    onClose={() => setIsOpen(false)}
                    data-testid='subscription-warning'
                    sx={{
                        borderRadius: '0',
                        '& .MuiAlert-message': {
                            marginLeft: 'auto',
                        },
                    }}
                >
                    {warningMessage}
                </Alert>
            )}
        </>
    );
}
