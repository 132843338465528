import { useMemo } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Box } from '@mui/material';

import { useAppSelector } from '@libs/reduxHooks';

import { Currency } from '@api/common/fetchCurrencies';

import { BaseOption, boundSelect } from '../PailheAutoComplete';

type CurrencyOption = BaseOption<string> & {
    textLabel: string;
};

function currencyToOptions(currency: Currency): CurrencyOption {
    return {
        id: currency.code,
        label: `${currency.code} ${getSymbolFromCurrency(currency.code)} ${currency.currency}`,
        textLabel: currency.currency,
    };
}

export const [SelectCurrency, SelectCurrencyControlled] = boundSelect(() => {
    const currencies = useAppSelector((state) => state.libs.currencies.data as Currency[]);
    const options = useMemo(() => currencies.map(currencyToOptions), [currencies]);

    return {
        options,
        renderOption: (props, option) => (
            <Box component='li' sx={{ display: 'flex' }} {...props}>
                <span style={{ width: '4rem' }}>{option.id}</span>
                <span style={{ width: '4rem' }}>{getSymbolFromCurrency(option.id)}</span>
                <span>{option.textLabel}</span>
            </Box>
        ),
    };
});
