import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@libs/reduxHooks';

import { boundSelect } from '../PailheAutoComplete';

export const [PailheSelectState, PailheSelectStateControlled] = boundSelect(
    ({ selectedCountry }) => {
        const selectedCountryTyped = selectedCountry as string;
        const { t } = useTranslation();
        const countriesStates = useAppSelector((state) => state.libs.countriesStates.data!);

        const options = Object.entries(countriesStates[selectedCountryTyped]).map(
            ([key, value]) => ({
                id: key,
                label: value,
            }),
        );

        return {
            options,
            TextFieldProps: {
                label:
                    selectedCountryTyped === 'CA'
                        ? t('payment_request_bank_information_bank_state_CA')
                        : t('payment_request_bank_information_bank_state_US'),
            },
        };
    },
);
