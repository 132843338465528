import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorIcon from '@mui/icons-material/Error';

interface ErrorViewProps {
    msg?: string;
    actionButtons?: React.ReactNode;
    errorIconStyle?: CSSProperties;
    customStyle?: Record<'container', CSSProperties>;
}
export function ErrorView({ msg, actionButtons, errorIconStyle, customStyle }: ErrorViewProps) {
    const { t } = useTranslation();
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ...customStyle?.container,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <ErrorIcon color='error' style={errorIconStyle} />
                <div>{msg || t('messaging_error_view_basic_msg')}</div>
                {actionButtons && (
                    <div
                        style={{
                            display: 'flex',
                            gap: '15px',
                            marginTop: '20px',
                        }}
                    >
                        {actionButtons}
                    </div>
                )}
            </div>
        </div>
    );
}
