import {
    ADVERTISER_ADS_LOADING,
    CLOSE_ALL_DIALOGS,
    DELETE_ADVERTISER_AD_FAILURE,
    DOWNLOAD_ADVERTISER_AD_FAILURE,
    FETCH_ADVERTISER_ADS_FAILURE,
    FETCH_ADVERTISER_ADS_SUCCESS,
    OPEN_ADVERTISER_AD_DELETE,
    OPEN_ADVERTISER_AD_PREVIEW,
    SEND_ADVERTISER_AD_FAILURE,
} from '@actions/advertiserAds';

import { AdData } from '@components/advertiser/ads/AdFormCreateEditPanel';

const INITIAL_STATE: AdvertiserAdsState = {
    loading: false,
    data: null,
    error: null,
    queryCriteria: {
        program: '',
        type: '',
        status: '',
        affiliateProfile: '',
        search: '',
    },
    previewAdDialog: {
        isOpen: false,
        ad: null,
    },
    deleteAdDialog: {
        isOpen: false,
        ad: null,
    },
};

export type AdvertiserAdsState = {
    loading: boolean;
    data: null | AdData[];
    error: null | unknown;
    queryCriteria: {
        program: string;
        type: string | null;
        status: string | null;
        affiliateProfile: string | null;
        search: string;
    };
    previewAdDialog: {
        isOpen: boolean;
        ad: null | unknown;
    };
    deleteAdDialog: {
        isOpen: boolean;
        ad: null | unknown;
    };
};

export default function (state = INITIAL_STATE, action): AdvertiserAdsState {
    switch (action.type) {
        case ADVERTISER_ADS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ADVERTISER_ADS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case OPEN_ADVERTISER_AD_PREVIEW: {
            return {
                ...state,
                previewAdDialog: {
                    isOpen: true,
                    ad: action.payload,
                },
            };
        }
        case FETCH_ADVERTISER_ADS_FAILURE:
        case DELETE_ADVERTISER_AD_FAILURE:
        case DOWNLOAD_ADVERTISER_AD_FAILURE:
        case SEND_ADVERTISER_AD_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case OPEN_ADVERTISER_AD_DELETE: {
            return {
                ...state,
                deleteAdDialog: {
                    isOpen: true,
                    ad: action.payload,
                },
            };
        }

        case CLOSE_ALL_DIALOGS: {
            return {
                ...state,
                previewAdDialog: {
                    isOpen: false,
                    ad: null,
                },
                deleteAdDialog: {
                    isOpen: false,
                    ad: null,
                },
                adFormDialog: {
                    isOpen: false,
                    action: '',
                    ad: null,
                },
            };
        }
        default:
            break;
    }

    return state;
}
