import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useAppSelector } from '@libs/reduxHooks';

import { Category } from '@api/common/fetchCategories';

import { BaseOption, boundSelect } from '../PailheAutoComplete';

export function categoriesToOptions(category: Category): BaseOption {
    return {
        id: category._id,
        label: category[`title_${i18next.language}` as 'title_fr' | 'title_en' | 'title_es'],
    };
}

export const [SelectCategory, SelectCategoryControlled] = boundSelect(() => {
    const { i18n } = useTranslation();
    const categories = useAppSelector((state) => state.libs.categories.data as Category[]);
    const options = useMemo(() => categories.map(categoriesToOptions), [categories, i18n.language]);
    return { options };
});
