import moment, { Moment } from 'moment';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { PeriodCmp, PeriodRef } from '@components/generic/DateRangePicker';

import { MongoId } from '@libs/types';

import { Metric } from '../utils/libs';

export type DashboardState = {
    selectedSubEntities: MongoId[];
    setSelectedSubEntities: (selectedSubEntities: MongoId[]) => void;

    currencySelected: string | undefined;
    setSelectedCurrency: (currencySelected: string | undefined) => void;

    showTopProducts: boolean;
    setShowTopProducts(showTopProducts: boolean): void;

    selectedReferencePeriod: PeriodRef;
    setSelectedReferencePeriod: (period: PeriodRef) => void;

    selectedComparisonPeriod: PeriodCmp;
    setSelectedComparisonPeriod: (period: PeriodCmp) => void;

    selectedKpi: Metric[];
    setSelectedKpi: (selectedKpi: Metric[]) => void;

    areFiltersComplete: () => boolean;

    reset: () => void;
};

const initialState = {
    selectedSubEntities: [],
    currencySelected: undefined,
    showTopProducts: true,
    selectedReferencePeriod: 'last_30_days' as PeriodRef,
    selectedComparisonPeriod: 'autoPrevious' as PeriodCmp,
    selectedKpi: [
        Metric.clicks,
        Metric.conversionsTotal,
        Metric.revenue,
        Metric.commissions,
        Metric.conversionRate,
        Metric.ROI,
    ],
} as const satisfies Partial<DashboardState>;

export const useDashboardStore = create<DashboardState>()(
    persist(
        (set, get) => ({
            ...initialState,
            setSelectedSubEntities: (selectedSubEntities) => set({ selectedSubEntities }),
            setSelectedCurrency: (currencySelected) => set({ currencySelected }),
            setShowTopProducts: (showTopProducts) => set({ showTopProducts }),
            setSelectedReferencePeriod: (period) => set({ selectedReferencePeriod: period }),
            setSelectedComparisonPeriod: (period) => set({ selectedComparisonPeriod: period }),
            setSelectedKpi: (selectedKpi) => set({ selectedKpi }),
            areFiltersComplete: () => {
                return (
                    get().currencySelected !== undefined &&
                    get().selectedReferencePeriod !== null &&
                    get().selectedComparisonPeriod !== null &&
                    get().selectedSubEntities.length > 0
                );
            },

            reset: () => set(initialState),
        }),
        {
            version: 2,
            migrate: (persistedState, version) => {
                if (version < 2) {
                    persistedState.selectedReferencePeriod = 'last_30_days';
                    persistedState.selectedComparisonPeriod = 'autoPrevious';
                }
                return persistedState;
            },
            name: 'dashboard-store',
            storage: createJSONStorage(() => localStorage, {
                reviver: (key, value) => {
                    if (key === 'from' || key === 'to') {
                        return moment.tzUser(value);
                    }
                    return value;
                },
                replacer: (key, value) => {
                    if (value instanceof moment) {
                        return (value as Moment).toISOString();
                    }
                    return value;
                },
            }),
        },
    ),
);
